import { IPaxshopData, IThirdPartyData } from 'types/init';
import { appConfig } from 'config';
import { captureException } from '@sentry/browser';
import { makeRequest } from '_middlewares/http';
import { googleTagManager, IGTMConfig } from '_helpers/googleTagManager';
import { initHotjar } from '_helpers/hotjar';

export function initPaxshop(paxshopId: number) {
  return {
    type: 'PAXSHOP_INIT',
    paxshopId,
  } as const;
}

export function markAnotherBookingTabLoggedIn() {
  return {
    type: 'PAXSHOP_ANOTHER_BOOKING_TAB_LOGGED_IN',
  } as const;
}

export function fetchInitialPaxshopData() {
  return makeRequest<IPaxshopData>({
    url: `${appConfig.apiUrl}/v4/info/paxshop/${appConfig.paxshopId}`,
    onSuccess: data => dispatch => {
      dispatch(handlePaxshopDataFetched(data));

      try {
        initializeTrackers(data.thirdPartyData);
      } catch (e) {
        captureException(e);
      }
    },
  });
}

export function handlePaxshopDataFetched(data: IPaxshopData) {
  return {
    type: 'PAXSHOP/FETCH_SUCCESS',
    payload: data,
  } as const;
}

function initializeTrackers(trackers: IThirdPartyData) {
  if (trackers) {
    const {
      googleAnalytics,
      googleAnalyticsCommon,
      hotjar,
      googleTagManager: googleTagManagerId,
    } = trackers;

    if (googleAnalytics?.startsWith('UA'))
      console.warn(
        `No support for Universal analytics. GoogleAnalytics with id "${googleAnalytics}" is ignored.`
      );
    if (googleAnalyticsCommon?.startsWith('UA'))
      console.warn(
        `No support for Universal analytics. GoogleAnalyticsCommon with id "${googleAnalyticsCommon}" is ignored.`
      );

    const multiFire = [googleAnalytics, googleAnalyticsCommon].reduce<
      IGTMConfig['multiFire']
    >((res, measurementId) => {
      if (measurementId && !measurementId.startsWith('UA')) {
        // eslint-disable-next-line no-param-reassign
        if (!res) res = [{ GA4measurementId: measurementId }];
        else res.push({ GA4measurementId: measurementId });
      }
      return res;
    }, undefined);
    if (googleTagManagerId) {
      googleTagManager.init({ gtmId: googleTagManagerId, multiFire });
    }
  }
}
