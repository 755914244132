import { appConfig, environment } from 'config';
// Source: https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce
// TODO: Types might not be 100% correct.

export type IGTMProduct = {
  /** Name or ID is required */ name?: string;
  /** Name or ID is required Eg. 12345 */ id?: string | number;
  /** Eg. 15.25 */ price?: string | number;
  /** Eg. Google */ brand?: string;
  /** Eg. Apparel */ category?: string;
  /** Eg. Gray */ variant?: string;
} & ({ name: string } | { id: string | number }) & {
    // TODO: Replace with Record<`dimension${number}, string>
    dimension1?: string;
    dimension2?: string;
    dimension3?: string;
    dimension4?: string;
    dimension5?: string;
    dimension6?: string;
  } & {
    // TODO: Replace with Record<`metric${number}, string>
    metric1?: string;
    metric2?: string;
    metric3?: string;
    metric4?: string;
    metric5?: string;
    metric6?: string;
  };

export type IGTMProductWithPosition = IGTMProduct & {
  position?: number;
};
export type IGTMProductWithList = IGTMProductWithPosition & {
  /** Eg. Search Results */ list?: string;
};

export type IGTMProductWithQuantity = IGTMProduct & {
  quantity: number;
};

export type IGTMProductWithCoupon = IGTMProductWithQuantity & {
  /** Optional fields may be omitted or set to empty string. */
  coupon?: string | '';
};

export type IGTMPromotion = {
  id?: string | number;
  name?: string;
  creative?: string;
  position?: string;
} & ({ name: string } | { id: string | number });

export type IGTMEvent = {
  event?: string;
  ecommerce?: {
    /** Local currency is optional */ currencyCode?: string;
    /** Measures product impressions and also tracks a standard
     * pageview for the tag configuration.
     * Product impressions are sent by pushing an impressions object
     * containing one or more impressionFieldObjects. */
    impressions?: IGTMProductWithList[];
    /**
     * Call this function when a user clicks on a product link. This function uses the event
     * callback datalayer variable to handle navigation after the ecommerce data has been sent
     * to Google Analytics.
     */
    click?: {
      actionField?: { list: string }; // Optional list property.
      products: IGTMProductWithPosition[];
    };
    /** Measure a view of product details. This example assumes the detail view occurs on pageload,
     * and also tracks a standard pageview of the details page.
     */
    detail?: {
      actionField?: { list: string }; // Optional list property.
      products: IGTMProduct[];
    };
    /**  Measure adding a product to a shopping cart by using an 'add' actionFieldObject
     * and a list of productFieldObjects. */
    add?: {
      products: IGTMProductWithQuantity[];
    };
    /** Measure the removal of a product from a shopping cart. */
    remove?: {
      products: IGTMProductWithQuantity[];
    };
    promoView?: {
      promotions: IGTMPromotion[];
    };
    promoClick?: {
      promotions: IGTMPromotion[];
    };
    checkout?: {
      actionField?: { step: number; option?: string };
      products: IGTMProductWithQuantity[];
    };
    checkout_option?: {
      actionField: { step: number; option: string };
    };
    purchase?: {
      actionField: {
        /** Transaction ID. Required for purchases and refunds. */ id:
          | string
          | number;
        /** Eg. Online Store */ affiliation?: string;
        /** Total transaction value (incl. tax and shipping) Eg. 35.43 */ revenue?:
          | string
          | number;
        /** Eg. 4.90 */ tax?: string | number;
        /** Eg. 5.99 */ shipping?: string | number;
        coupon?: string;
      };
      products: IGTMProductWithCoupon[];
    };
    refund?: {
      actionField: {
        /** Transaction ID. Required for purchases and refunds. */ id: string;
      };
      /** Product ID and quantity. Required for partial refunds. */
      products?: IGTMProductWithQuantity[];
    };
    eventCallback?: () => void;
  };
};
export interface IGTMConfig {
  gtmId: string;
  layerId?: string;
  /** If set, the tag will be fired once for every object in the array,
   * with the object added to the dataLayser object.
   * This makes it possible to fire with multiple Google Analytics Id. */
  multiFire?: Record<string, string>[];
}
interface IGTMConfigUsed extends IGTMConfig {
  layerId: string;
}

class GoogleTagManagerService {
  /**
   * Since there maybe more than 1 data layer,
   * we store them here, and then send an event to every
   */
  private readonly existingDataLayers: Record<string, IGTMConfigUsed> = {};

  public init(config: IGTMConfig): void {
    const usedConfig: IGTMConfigUsed = { layerId: 'dataLayer', ...config };

    this.existingDataLayers[usedConfig.layerId] = usedConfig;

    window[usedConfig.layerId] = window[usedConfig.layerId] || [];
    this.pushToDataLayer(
      { event: 'gtm.js' },
      { 'gtm.start': new Date().getTime() },
      usedConfig
    );

    const firstScriptOnPage = document.getElementsByTagName('script')[0];
    const gtmScriptTag = document.createElement('script');
    const dataLayerIdParmeter =
      usedConfig.layerId === 'dataLayer' ? '' : `&l=${usedConfig.layerId}`;

    gtmScriptTag.async = true;
    gtmScriptTag.src = `https://www.googletagmanager.com/gtm.js?id=${usedConfig.gtmId}${dataLayerIdParmeter}`;
    firstScriptOnPage.parentNode.insertBefore(gtmScriptTag, firstScriptOnPage);
  }

  public pushToDataLayer(
    event: IGTMEvent,
    customProperties?: Record<string, string | number>,
    useConfig?: IGTMConfigUsed
  ): void {
    const dataLayers = useConfig
      ? [useConfig]
      : Object.values(this.existingDataLayers);
    dataLayers.forEach((config): void => {
      (config.multiFire ? config.multiFire : [{}]).forEach(multiFireObject => {
        if (window[config.layerId]) {
          // https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce#clear-ecommerce
          window[config.layerId].push({ ecommerce: null }); // Clear the previous ecommerce object.
          window[config.layerId].push({
            ...event,
            ...customProperties,
            ...multiFireObject,
          });
          window[config.layerId].push({ ecommerce: null }); // Clear ecommerce object (for next event).
        }
      });
    });
  }
}

export const googleTagManager = new GoogleTagManagerService();

const specialGATagConfig: Partial<Record<typeof environment, string>> = {
  testing: appConfig.specialGtmIdTest,
  production: appConfig.specialGtmIdProduction,
};
const specialGtmId = specialGATagConfig[environment];

if (specialGtmId) {
  googleTagManager.init({ gtmId: specialGtmId, layerId: 'specialDataLayer' });
}
