import { appConfig } from 'config';
import * as authActions from 'authorization/actions';
import { initRoutingAuthWatcher } from 'authorization/helpers/authWatcher';
import { isLoggedInSelector } from 'authorization/selectors';
import * as basketActions from 'basket/actions/index';
import { renderPaxshop } from 'reactMainStarter';
import { setupRouting } from 'routing/routing';
import { IPaxshopBootstrapStatus } from 'types/init';
import { IPaxshopReduxStore } from 'types/store';
import { markAnotherBookingTabLoggedIn, initPaxshop } from './actions';
import {
  createBootstrapStatusPromise,
  displayBootstrapError,
  getSrcAuthData,
} from './common';
import { detectAppropriateStorageSupport } from '../_helpers/detectStorageEventSupport';
import localStorageHelper from "../_helpers/localStorage";

function initShop(): void {
  const rootDomNode = document.getElementById('appRoot');
  setupRouting();
  initRoutingAuthWatcher();
  renderPaxshop(rootDomNode);
}

export function onAppInit(store: IPaxshopReduxStore) {
  const handleOtherTabLogin = event => {
    const authSrcData = store.getState().auth.authSrcData.data;
    const currentBookingNumber =
      authSrcData &&
      authSrcData.bookingSrc &&
      authSrcData.bookingSrc.bookingNumber;
    const localStorageKey = appConfig.baseUrl;
    const isLoggedIn = isLoggedInSelector(store.getState());

    if (localStorageKey === event.key) {
      const updatedLocalStorageState = JSON.parse(event.newValue);
      const newBookingNumber =
        updatedLocalStorageState.bookingSrcData &&
        updatedLocalStorageState.bookingSrcData.bookingNumber;

      if (isLoggedIn && currentBookingNumber !== newBookingNumber) {
        store.dispatch(markAnotherBookingTabLoggedIn());
      }
    }
  };

  store.dispatch(initPaxshop(appConfig.paxshopId));
  const srcAuth = getSrcAuthData(
    store.getState().init.paxshopInitParams,
    store.getState().init.paxshopAppCtx
  );
  if (srcAuth.srcAuthData) {
    const initParams = store.getState().init.paxshopInitParams;

    if (initParams.deepLink) {
      const oldBookingNumber = (localStorageHelper.getBookingSrcData())? localStorageHelper.getBookingSrcData().bookingNumber : "";
      if (initParams.initialPath !== "payment-failed" || oldBookingNumber !== initParams.deepLink.bookingNumber) {
        store.dispatch(basketActions.clearBasket());
      }
    }

    store.dispatch(
      authActions.initiateLogin({
        authType: 'BOOKING',
        bookingSrc: srcAuth.srcAuthData,
      })
    );
  }
  const bootstrapPromise = createBootstrapStatusPromise(store);

  bootstrapPromise.then((res: IPaxshopBootstrapStatus) => {
    if (res.shopInitError) {
      displayBootstrapError(null);
    } else if (srcAuth.failOnAuthError) {
      displayBootstrapError(null);
    } else {
      detectAppropriateStorageSupport(supported => {
        if (supported) window.addEventListener('storage', handleOtherTabLogin);
      });

      initShop();
    }
  });
}
